import React from 'react';
import './Form.css';
import CircularProgress from '@mui/material/CircularProgress';
import SignInForm from './components/SignInForm';
import SignUpForm from './components/SignUpForm';
import AccountForm from './components/AccountForm';
import AccountMenu from './components/AccountMenu';
import ForgotPassword from './components/ForgotPassword';
import CustomRight from './components/CustomRight';
import Bookings from './components/Bookings';
import moment from 'moment-timezone';
import swal from 'sweetalert';
import remoteApi from './api/remoteApi';

class WizardFormLandingPage6 extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      businessData: {},
      componentToRender: 5,
      userOrderData: [],
      userOrderEventData: [],
      userPreviousOrderData: [],
      userUpcomingOrderData: [],
      userRecurringOrderData: [],
      userRecurringEventData: [],
      previousBookingsCollapsed: true
    }
    this.changeScreen = this.changeScreen.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.bookAgain = this.props.bookAgain.bind(this);
    this.setUser = this.props.setUser.bind(this);
    this.signInProceed = this.signInProceed.bind(this);
    this.signOut = this.signOut.bind(this);
    this.editAccount = this.editAccount.bind(this);
    this.handleDropDownClick = this.handleDropDownClick.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }

  async componentDidMount() {
    if(!this.props.user) {
      this.checkLoggedIn();
    }
  }

  signInProceed(){
    this.checkLoggedIn();
    this.setState({componentToRender: 5});
  }

  changeScreen() {
    if(this.state.componentToRender === 0){
      this.props.previousPage();
    } else if(this.state.componentToRender === 1){
      this.setState({ componentToRender: 0 });
    } else if(this.state.componentToRender === 2){
      this.setState({ componentToRender: 0 });
    } else if(this.state.componentToRender === 3){
      this.setState({ componentToRender: 5 });
    } else if(this.state.componentToRender === 4){
      this.setState({ componentToRender: 5 });
    }
  }

  createAccount()
  {
    this.setState({ componentToRender: 1 });
  }

  forgotPassword()
  {
    this.setState({ componentToRender: 2 });
  }

  async checkLoggedIn() {
    try {
      let response = await remoteApi.getUser();
      this.props.setUser(response.data.userData);
    } catch(e) {
      this.setState({ loading: false, componentToRender: 0 });
    }
  }

  async getBookings() {
    try {
      let response = await remoteApi.getBookings({ business_id: this.props.business_id });
      let data = response.data;
      let userUpcomingOrderData = [], userPreviousOrderData = [];
      let timezoneName = this.props.timezone_name;
      // Sort into upcoming and previous
      data.order_data.forEach((order_data_obj,) => {
        let order_data_events = data.order_event_data.filter(x => x.user_order_id == order_data_obj.user_order_id);
        if(order_data_events.some(x => moment(x.start, 'YYYY-MM-DD HH:mm:ss').tz(timezoneName, true) > moment().tz(timezoneName))){
          userUpcomingOrderData.push(order_data_obj);
        } else {
          userPreviousOrderData.push(order_data_obj);
        }
      });
      // Arrange upcoming orders by date
      if(userUpcomingOrderData.length > 0){
        userUpcomingOrderData.sort((a,b) => {
          let a_data_events = data.order_event_data.filter(x => x.user_order_id == a.user_order_id);
          let b_data_events = data.order_event_data.filter(x => x.user_order_id == b.user_order_id);
          if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss') < moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss')){
            return -1;
          }
          if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss') > moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss')){
            return 1;
          }
          return 0;
        });
      }
      // Arrange previous orders by date
      if(userPreviousOrderData.length > 0){
        userPreviousOrderData.sort((a,b) => {
          let a_data_events = data.order_event_data.filter(x => x.user_order_id == a.user_order_id);
          let b_data_events = data.order_event_data.filter(x => x.user_order_id == b.user_order_id);
          if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss') > moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss')){
            return -1;
          }
          if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss') < moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss')){
            return 1;
          }
          return 0;
        });
      }
      // Go through recurring orders and assign next date
      if(data.order_recurring_data.length > 0){
        data.order_recurring_data.forEach((order_recurring_data_obj, index) => {
          try {
            let order_recurring_data_events = data.order_recurring_event_data.filter(x => x.user_order_recurring_id == order_recurring_data_obj.user_order_recurring_id);
            var cur_date = moment(order_recurring_data_events[0].start_date, 'YYYY-MM-DD').tz(timezoneName, true);
            let business_date = moment().tz(timezoneName, false);
            // If start date has not occured yet
            if(cur_date.isBefore(business_date, 'day')){
              // Loop to find next occurrence
              while (cur_date.isBefore(business_date, 'day')) {
                cur_date.add(order_recurring_data_events[0].frequency_magnitude, order_recurring_data_events[0].frequency_unit);
              }
              if(order_recurring_data_events[0].end_date && moment(order_recurring_data_events[0].end_date, 'YYYY-MM-DD').tz(timezoneName).isBefore(cur_date, 'day')){
                cur_date = null;
              }
            } else {
              cur_date = null;
            }
          } catch(err) {
            var cur_date = null;
          }
          data.order_recurring_data[index].next_date = cur_date;
        });
      }
      // Set state
      this.setState({
        loading: false,
        userOrderData: [],
        userUpcomingOrderData: userUpcomingOrderData,
        userPreviousOrderData: userPreviousOrderData,
        userOrderEventData: data.order_event_data,
        userRecurringOrderData: data.order_recurring_data,
        userRecurringEventData: data.order_recurring_event_data
      });
    } catch(e) {
      this.setState({ loading: false });
    }
  }

  async signOut() {
    try {
      this.setState({loading: true});
      await remoteApi.signOut();
      this.props.setSignOut();
    } catch(e) {
      this.setState({loading: false});
    }
  }

  cancelOrder(inputUserOrderId) {
    let orderEvents = this.state.userOrderEventData.filter(x => x.user_order_id === inputUserOrderId);
    if(orderEvents.length === 0 || (orderEvents[0].status === 'Cancelled')) {
      return;
    }
    let isCancelAllowed = moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').tz(this.props.timezone_name, true).diff(moment().tz(this.props.timezone_name, false), 'hour', true) > this.props.business_cancellation_period;
    if(isCancelAllowed) {
      swal({
        title: "Confirm cancellation",
        text: "Are you sure that you want to cancel this booking?",
        icon: "warning",
        buttons: {
          confirm: { text: "Yes", value: true, visible: true, className: "swal-custom-btn", closeModal: true },
          cancel: { text: "No", value: null, visible: true, className: "", closeModal: true }
        },
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          this.confirmCancelOrder(inputUserOrderId, false);
        }
      });
    } else {
      let cancellationPeriodStr;
      if(this.props.business_cancellation_period > 24) {
        let dayCancellationPeriod = Math.round(this.props.business_cancellation_period / 24);
        cancellationPeriodStr = dayCancellationPeriod + " day";
      } else {
        cancellationPeriodStr = this.props.business_cancellation_period + " hour";
      }
      if(this.props.business_cancellation_fee_enabled && this.props.business_cancellation_fee_enabled === 1 && this.props.business_cancellation_fee_percentage && this.props.business_cancellation_fee_percentage > 0) {
        swal({
          title: "Cancellation Fee",
          text: this.props.business_name + " operates a " + cancellationPeriodStr + " cancellation policy. You can still choose to cancel but you will be charged a late-cancellation fee of " + this.props.business_cancellation_fee_percentage + "% of the total booking value. Are you sure you want to cancel?",
          icon: "warning",
          buttons: {
            confirm: { text: "Yes", value: true, visible: true, className: "swal-custom-btn", closeModal: true },
            cancel: { text: "No", value: null, visible: true, className: "", closeModal: true }
          },
          dangerMode: true,
        }).then(willDelete => {
          if (willDelete) {
            this.confirmCancelOrder(inputUserOrderId, true);
          }
        });  
      } else {
        swal({
          title: "Unable to cancel",
          text: this.props.business_name + " operates a " + cancellationPeriodStr + " cancellation policy.",
          icon: "error",
          buttons: {
            cancel: { text: "Ok", value: null, visible: true, className: "", closeModal: true }
          },
        });
      }
    }
  }

  async confirmCancelOrder(inputUserOrderId, bookingFee = false) {
    this.setState({loading: true});
    try {
      await remoteApi.cancelBooking({ user_order_id: inputUserOrderId, booking_fee: bookingFee });
      let order_events = this.state.userOrderEventData;
      order_events.forEach(function(obj, index) {
        if(obj.user_order_id === inputUserOrderId){
          order_events[index].status = 'Cancelled';
        }
      });
      this.setState({loading: false, userOrderEventData: order_events});
      swal({
        title: "Cancelled",
        text: "Your booking has been cancelled.",
        icon: "success",
        buttons: false,
        timer: 2500,
        timerProgressBar: true
      });
    } catch(respError) {
      let errorMessage = null;
      if(respError.response && respError.response.data && respError.response.data.message) {
          errorMessage = "We were unable to cancel this booking. " + respError.response.data.message;
      } else {
          errorMessage = "An error occurred and we were unable to cancel this booking. If the problem persists, please contact support@styler.digital."
      }
      swal({
        title: "Oops.",
        text: errorMessage,
        icon: "error",
        buttons: false,
        timer: 2500,
        timerProgressBar: true
      });
      this.setState({ loading: false });
    }
  }

  editAccount() {
    this.setState({componentToRender: 4});
  }

  accountMenu = () => {
    this.setState({componentToRender: 5});
  }

  navigateBookingsScreen = () => {
    this.getBookings();
    this.setState({componentToRender: 3});
  }

  handleDropDownClick(param) {
    switch(param) {
      case 'Account':
        this.editAccount();
        break;
      case 'New Booking':
        if(this.props.booking_enabled !== 1) {
          return;
        }
        this.props.bookAgain();
        break;
      case 'Sign out':
        this.signOut();
        break;
      default:
        break;
    }
  }

  render() {
    if(this.state.componentToRender === 3){
      return (
          <div>
            {this.state.loading ? <div className="loading-container" style={{width: '100%'}}>
              <div className="loading-container-spinner">
                <CircularProgress size={45} color={'secondary'}/>
              </div>
            </div> : null}
            <CustomRight
              business_img={this.props.business_img}
              booking_enabled={this.props.booking_enabled}
              bookAgain={this.bookAgain}
              componentToRender={this.state.componentToRender}
              user={this.props.user}
              signOut={this.signOut}
              accountMenu={this.accountMenu}
            />
            <Bookings
              userOrderEventData={this.state.userOrderEventData}
              userPreviousOrderData={this.state.userPreviousOrderData}
              userUpcomingOrderData={this.state.userUpcomingOrderData}
              userRecurringOrderData={this.state.userRecurringOrderData}
              userRecurringEventData={this.state.userRecurringEventData}
              businessSettings={this.props.business_settings}
              businessCancellationPeriod={this.props.business_cancellation_period}
              timezoneName={this.props.timezone_name}
              handleDropDownClick={this.handleDropDownClick}
              cancelOrder={this.cancelOrder}
              businessCurrency={this.props.business_currency}
              bookAgain={this.bookAgain}
              bookingEnabled={this.props.booking_enabled}
              changeScreen={this.changeScreen}
            />
          </div>
      );
    } else if(this.state.componentToRender === 5) {
      return (
        <AccountMenu
          user={this.props.user}
          business_img={this.props.business_img}
          signOut={this.signOut}
          editAccount={this.editAccount}
          navigateBookingsScreen={this.navigateBookingsScreen}
          bookingEnabled={this.props.booking_enabled}
          bookAgain={this.bookAgain}
          businessId={this.props.business_id}
          businessLocationData={this.props.business_location_data}
          businessStripeAccount={this.props.business_stripe_account}
          elements={this.props.elements}
          stripe={this.props.stripe}
        />
      );
    } else if(this.state.componentToRender === 0) {
      return (
        <div>
          <CustomRight
            business_img={this.props.business_img}
            booking_enabled={this.props.booking_enabled}
            bookAgain={this.bookAgain}
            componentToRender={this.state.componentToRender}
            user={this.props.user}
            signOut={this.signOut}
            businessLogo={this.props.business_logo}
            accountMenu={this.accountMenu}
          />
          <SignInForm
            changeScreen={this.changeScreen}
            createAccount={this.createAccount}
            forgotPassword={this.forgotPassword}
            nextPage={this.signInProceed}
            backNavigation={false}
            business_id={this.props.business_id}
            booking_data={this.props.booking_data}
            embedded={this.props.embedded}
            page={this.props.page}
            socialRedirectError={this.props.socialRedirectError}
          />
        </div>
      );
    } else if(this.state.componentToRender === 1) {
      return (
        <div>
          <CustomRight
            business_img={this.props.business_img}
            booking_enabled={this.props.booking_enabled}
            bookAgain={this.bookAgain}
            componentToRender={this.state.componentToRender}
            user={this.props.user}
            signOut={this.signOut}
            businessLogo={this.props.business_logo}
            accountMenu={this.accountMenu}
          />
          <SignUpForm
            changeScreen={this.changeScreen}
            nextPage={this.signInProceed}
            business_id={this.props.business_id}
          />
        </div>
      );
    } else if(this.state.componentToRender === 2) {
      return (
        <div>
          <CustomRight
            business_img={this.props.business_img}
            booking_enabled={this.props.booking_enabled}
            bookAgain={this.bookAgain}
            componentToRender={this.state.componentToRender}
            user={this.props.user}
            signOut={this.signOut}
            businessLogo={this.props.business_logo}
            accountMenu={this.accountMenu}
          />
          <ForgotPassword
            changeScreen={this.changeScreen}
            business_id={this.props.business_id}
          />
        </div>
      );
    } else if(this.state.componentToRender === 4) {
      return (
        <div>
          <CustomRight
            business_img={this.props.business_img}
            booking_enabled={this.props.booking_enabled}
            bookAgain={this.bookAgain}
            componentToRender={this.state.componentToRender}
            user={this.props.user}
            signOut={this.signOut}
            businessLogo={this.props.business_logo}
            accountMenu={this.accountMenu}
          />
          <AccountForm
            changeScreen={this.changeScreen}
            nextPage={this.signInProceed}
            business_id={this.props.business_id}
            businessSettings={this.props.business_settings}
            user={this.props.user}
            signOut={this.signOut}
            setUser={this.setUser}
          />
        </div>
      );
    }
  }
};

export default (WizardFormLandingPage6);