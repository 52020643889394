import React from 'react';
import Button from "./components/materialComponents/CustomButtons/Button.js";
import Slide from '@mui/material/Slide';
import './Form.css';
import BusinessServices from './components/BusinessServices';
import { FiShoppingCart, FiChevronLeft, FiArrowRight } from 'react-icons/fi';

let WizardFormLandingPage2 = props => {
  const { 
    addService,
    removeService,
    booking_data,
    accountPage,
    nextPage,
    previousPage,
    business_id,
    business_location_id,
    business_location_staff_map_data,
    isActive,
    service_category_data,
    service_data,
    service_detail_data,
    staff_data,
    service_staff_map_data,
    service_location_map_data,
    business_settings,
    toggleShopCart,
    business_currency,
    linked_service_id
  } = props;
  let serviceData = service_data;
  if(linked_service_id) {
    serviceData = serviceData.filter(x => x.service_id === linked_service_id);
  }
  return (
    <div className="left">
      <div className="page-title" style={{backgroundColor: business_settings?.service_header_background, color: business_settings?.service_header_title}}>
        <div style={{ display: 'flex' }}>
          <div onClick={previousPage} style={{ cursor: 'pointer', marginRight: 10 }}>
            <FiChevronLeft size={20} style={{ marginBottom: -3 }} color={business_settings?.service_header_title}/>
          </div>
          <div>
            Services
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Button size="sm" onClick={accountPage}>
            Account
          </Button>
          <Button id="cartToggleBtn" className="book-button-header" size="sm" onClick={() => { toggleShopCart() }}>
            <FiShoppingCart /> {booking_data ? booking_data.length : 0}
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <BusinessServices
          booking_data={booking_data}
          addService={addService}
          removeService={removeService}
          business_id={business_id}
          business_location_id={business_location_id}
          business_location_staff_map_data={business_location_staff_map_data}
          isActive={(isActive)}
          service_category_data={service_category_data} 
          service_data={serviceData}
          service_detail_data={service_detail_data}
          staff_data={staff_data}
          service_staff_map_data={service_staff_map_data}
          service_location_map_data={service_location_map_data}
          business_settings={business_settings}
          currency={business_currency}
          linked_service_id={linked_service_id}
        />
      </div>
      <div className="sticky-next-button-container">
        <Slide direction="up" in={booking_data.length > 0} mountOnEnter unmountOnExit>
          <Button block style={{ margin: 0, borderRadius: 0 }} onClick={nextPage}>
            Next <FiArrowRight style={{ marginLeft: 5 }}/>
          </Button>
        </Slide>
      </div>
    </div>
  );


};


export default WizardFormLandingPage2