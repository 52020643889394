import React from "react";
import Dialog from '@mui/material/Dialog';
import { FiPlus } from 'react-icons/fi';
import { mediaUrl } from './../utils/constants';

export const StaffPicker = props => {
    return (
        <div>
            <Dialog onClose={props.toggleVisible} open={props.visible}>
                <div className="staff-picker-header" style={{ color: props.business_settings?.staff_header_title, backgroundColor: props.business_settings?.staff_header_background }}>
                    Select Staff Member
                </div>
                <div>
                    {props.data && props.data.map((dataObj, dataIndex) => {
                        return (
                            <div key={'staff' + dataObj.id} onClick={() => props.submit(dataObj.id)}>
                                <div className="service-line"/>
                                <div className="staff-picker-item">
                                    <img
                                        src={mediaUrl + dataObj.staff_img}
                                        className="staff-img"
                                        height="45"
                                        width="45"
                                    />
                                    <div className="cart-item-title" style={{color: props.business_settings?.staff_name_title}}>
                                        {dataObj.firstname + ' ' + dataObj.lastname}
                                        <div className="service-description" style={{color: props.business_settings?.staff_position_title}}>
                                            {dataObj.position}
                                        </div>
                                    </div>
                                    <div style={{ alignSelf: 'center' }}>
                                        <FiPlus style={{ marginLeft: 5 }} color={props.business_settings?.staff_name_title}/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Dialog>
        </div>
    );
};