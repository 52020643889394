import { Box, Typography, Button } from "@mui/material";

import { formatMinuteIntToTime } from "../utils/formatting";

function AvailabilitySlot({ timeInt, isSelected, setSelectedTime, styleSettings }) {
    let backgroundColor;
    let color;
    if(isSelected) {
        backgroundColor = '#04C000';
        color = '#ffffff';
    } else {
        backgroundColor = styleSettings?.times_background ? styleSettings.times_background : '#ffffff';
        color = styleSettings?.times_time_title ? styleSettings.times_time_title : 'unset';
    }
    return (
        <Button variant="text" sx={{ padding: 1, borderRadius: 2, backgroundColor, color }} fullWidth onClick={() => setSelectedTime(timeInt)}>
            <Typography sx={{ fontSize: 16, fontWeight: '500', textAlign: 'center' }}>
                {formatMinuteIntToTime(timeInt)}
            </Typography>
        </Button>
    );
}

export default AvailabilitySlot;