import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FiChevronRight } from 'react-icons/fi';

import AvailabilitySlot from './AvailabilitySlot';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { grey } from '@mui/material/colors';

function Availability(props) {

  const { availabilityData, selectedTime, isSelectedDate, setSelectedTime, styleSettings } = props;
  if(!availabilityData) return;

  const renderAvailabilitySection = (sectionName, availabilityData) => {
    let sectionAvailability;
    switch(sectionName) {
      case 'morning':
        sectionAvailability = availabilityData.filter(x => x < 720);
        break;
      case 'afternoon':
        sectionAvailability = availabilityData.filter(x => x >= 720 && x < 1080);
        break;
      case 'evening':
        sectionAvailability = availabilityData.filter(x => x >= 1080);
        break;
    }
    if(sectionAvailability.length === 0) return;
    return (
      <Box sx={{ mb: 2 }} key={sectionName}>
        <Typography
          sx={{ textTransform: 'uppercase', color: grey[400], fontSize: 16, fontWeight: '500', mb: 2 }}
        >
          {sectionName}
        </Typography>
        <Grid container columns={{ xs: 4, sm: 5 }} rowSpacing={2} columnSpacing={2}>  
          {sectionAvailability.map(x => {
            return (
              <Grid size={1} key={x}>
                <AvailabilitySlot
                  timeInt={x}
                  isSelected={selectedTime === x && isSelectedDate}
                  setSelectedTime={setSelectedTime}
                  styleSettings={styleSettings}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const availabilitySections = ['morning', 'afternoon', 'evening'];
  return availabilitySections.map(x => {
    return renderAvailabilitySection(x, availabilityData);
  });
}

export default Availability;