import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localeData';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import { IconButton, Box, Typography, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { grey } from '@mui/material/colors';

dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
// Set the start of the week to Monday
dayjs.Ls.en.weekStart = 1;

function CalendarHeader(props) {
    const { weekDates, updateWeekDates, minDate, maxDate, toggleMonthCalendar } = props;
    const isSameMonth = weekDates[0].isSame(weekDates[6], 'month');
    let startMonthStr;
    let endMonthStr;
    if (isSameMonth) {
        startMonthStr = weekDates[0].format('MMMM YYYY');
        endMonthStr = null;
    } else {
        const isSameYear = weekDates[0].isSame(weekDates[6], 'year');
        startMonthStr = weekDates[0].format(`MMM${!isSameYear ? ' YYYY' : ''}`)
        endMonthStr = weekDates[6].format('MMM YYYY')
    }
    const isBackDisabled = minDate?.isSameOrAfter(weekDates[0], 'day');
    const isForwardDisabled = maxDate?.isSameOrBefore(weekDates[6], 'day');
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, flex: 1 }}>
            <IconButton onClick={() => updateWeekDates(-1)} disabled={isBackDisabled}>
                <FiChevronLeft/>
            </IconButton>
            <Button variant="text" sx={{ textTransform: 'unset' }} onClick={toggleMonthCalendar}>
                <Typography sx={{ fontWeight: '500', fontSize: '1.3rem', pt: 0.5 }}>{isSameMonth ? startMonthStr : `${startMonthStr} - ${endMonthStr}`}</Typography>
            </Button>
            <IconButton onClick={() => updateWeekDates(1)} disabled={isForwardDisabled}>
                <FiChevronRight/>
            </IconButton>
        </Box>
    );
}

function AvailabilityMarker(props) {
    const { variant, dateStr } = props;
    let color = null;
    let count = 0;
    switch(variant) {
        case 'high':
            color = '#04C000';
            count = 3;
            break;
        case 'medium':
            color = '#FFA800';
            count = 2;
            break;
        case 'low':
            color = '#FF0000';
            count = 1;
            break;
        default:
            return;
    }
    const marker = (markerKey) => <Box key={markerKey} sx={{ height: 4, width: 4, backgroundColor: color, borderRadius: '50%' }} />;
    return (
        <Box sx={{ display: 'flex', gap: '2px' }}>
            {Array.from({ length: count }).map((_,idx) => marker(`${dateStr}M${idx}`))}
        </Box>
    );
}

function getMarkerVariant(availabilityLength) {
    if(availabilityLength <= 3) {
        return 'low';
    } else if(availabilityLength > 3 && availabilityLength <= 6) {
        return 'medium';
    } else if(availabilityLength > 6) {
        return 'high';
    }
    return null;
}

function DateOption(date, firstDayOfWeek,selectedDate, setSelectedDate, availability) {
    const isSelected = date.isSame(selectedDate, 'day');
    let dateColor;
    let isDisabled=false;
    if (isSelected) {
        dateColor = 'unset';
    } else if (date.isBefore(dayjs(), 'day')) {
        dateColor = grey[300];
        isDisabled=true;
    } else if (availability.length === 0) {
        dateColor = grey[300];
        isDisabled=true;
    } else if(!date.isSame(firstDayOfWeek, 'month')) {
        dateColor = grey[500];
    } else {
        dateColor = '#000000';
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }} onClick={() => !isDisabled && setSelectedDate(date)}>
            <Typography color="textSecondary" sx={{ fontWeight: '600', fontSize: '0.7rem' }}>{date.format('ddd')}</Typography>
            <Button
                sx={{ height: 35, width: 35, my: 0.2, boxShadow: 'none', p: 0, borderRadius: '50%', minWidth: 'unset' }}
                variant={isSelected ? "contained" : "text"}
                disableFocusRipple
                disabled={isDisabled}
            >
                <Typography sx={{ fontSize: '1.2rem', color: dateColor }}>{date.date()}</Typography>
            </Button>
            {!isDisabled && <AvailabilityMarker dateStr={date.format('YYYY-MM-DD')} variant={getMarkerVariant(availability.length)}/>}
        </Box>
    );
}

function WeekPicker(props) {
    const { dates, calendarDate, setCalendarDate, availabilityData } = props;
    return (
        <Box sx={{ flex: 1, maxWidth: 400, width: '100%', alignSelf: 'center', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {dates.map(x => {
                    const formattedDate = x.format('YYYY-MM-DD');
                    const availability = formattedDate in availabilityData ? availabilityData[formattedDate] : [];
                    return DateOption(x, dates[0], calendarDate, setCalendarDate, availability)
                })}
            </Box>
        </Box>
    );
}

function CalendarPicker(props) {
    const { minDate, maxDate, calendarDate, setCalendarDate, setVisible, availabilityData } = props;
    
    const markedDates = (props) => {
        const { day, outsideCurrentMonth, ...other } = props;
        const dateStr = day.format('YYYY-MM-DD');
        const isDisabled = !(dateStr in availabilityData) || availabilityData[dateStr].length === 0;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
                {!(isDisabled || outsideCurrentMonth) && (
                    <Box sx={{ my: '1px' }}>
                        <AvailabilityMarker variant={getMarkerVariant(availabilityData[dateStr].length)} dateStr={dateStr} />
                    </Box>
                )}
            </Box>
        )
    }
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                minDate={minDate}
                maxDate={maxDate}
                views={['day']}
                sx={{
                    '& .MuiPickersDay-root': {
                        fontSize: '1.1rem'
                    },
                    '& .MuiPickersSlideTransition-root': {
                        overflowX: 'unset'
                    },
                    '& .MuiDayCalendar-weekContainer': {
                        marginBottom: 0.5
                    }
                }}
                value={calendarDate}
                onChange={(e) => { setCalendarDate(e); setVisible(e); }}
                shouldDisableDate={(day) => {
                    const formattedDate = day.format('YYYY-MM-DD');
                    return !(formattedDate in availabilityData) || availabilityData[formattedDate].length === 0;
                }}
                slots={{
                    day: markedDates
                }}
            />
        </LocalizationProvider>
    )
}

const getWeekDates = (date) => {
    const startOfWeek = dayjs(date).startOf('week');
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
        weekDays.push(startOfWeek.add(i, 'day'));
    }
    return weekDays;
};

function Calendar(props) {
    const { availabilityData, calendarDate, setCalendarDate, minDate, maxDate } = props;
    // State
    const [weekDates, setWeekDates] = useState(getWeekDates(calendarDate));
    const [monthCalendarVisible, setMonthCalendarVisible] = useState(false);

    const toggleMonthCalendar = (x) => {
        if(x) setWeekDates(getWeekDates(x));
        setMonthCalendarVisible(!monthCalendarVisible);
    }

    const updateWeekDates = (y) => {
        setWeekDates(weekDates.map(x => dayjs(x).add(y, 'week')));
    };
    return (
        <Box sx={{ backgroundColor: '#ffffff', px: (monthCalendarVisible ? 0 : 2), pt: (monthCalendarVisible ? 0 : 2), pb: 1, display: 'flex', flexDirection: 'column', boxShadow: '1px 11px 49px -18px rgba(0,0,0,0.1);' }}>
            {monthCalendarVisible && <CalendarPicker setVisible={toggleMonthCalendar} {...props}/>}
            {!monthCalendarVisible && (
                <>
                    <CalendarHeader weekDates={weekDates} updateWeekDates={updateWeekDates} minDate={minDate} maxDate={maxDate} toggleMonthCalendar={toggleMonthCalendar} />
                    <WeekPicker dates={weekDates} calendarDate={calendarDate} setCalendarDate={setCalendarDate} availabilityData={availabilityData}/>
                </>
            )}
            <Box sx={{ backgroundColor: '#f0f0f0', height: 5, width: 40, mt: 2, alignSelf: 'center', cursor: 'pointer' }} onClick={() => toggleMonthCalendar()}></Box>
        </Box>
    );
}

export default Calendar;