import React from 'react';
import { Box, Typography } from '@mui/material';

function NoAvailability(props) {
    let targetMsg;
    switch(props.variant) {
        case 'service':
            targetMsg = ' for your chosen services.';
            break;
        case 'date':
            targetMsg = ' for this date.';
            break;
        default:
            targetMsg = '.';
    }
    return (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', flex: 0.6, p: 1, textAlign: 'center', borderRadius: 2 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>No availability</Typography>
                <Typography variant="body2">Sorry, we don't have any availability {targetMsg}</Typography>
            </Box>
        </Box>
  );
}

export default NoAvailability;