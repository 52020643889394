import React from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';


export default function MaterialThemeProvider(props) {
    const { children, businessSettings } = props;
    const theme = createTheme({
        palette: {
          primary: {
            // Purple and green play nicely together.
            main: businessSettings && businessSettings.landing_header_background ? businessSettings.landing_header_background : '#192027',
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#ffffff',
          },
        },
        typography: {
            fontFamily: [
              'Poppins',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
            ].join(','),
        },
    });
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    );
}