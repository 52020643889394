import axios from 'axios';

let baseUrl;
switch(window?.location.hostname) {
    case "booking.styler.digital":
        baseUrl = "https://api.styler.digital/availability";
        break;
    case "localhost":
        baseUrl = "http://localhost:8080/availability";
        break;
    default:
        console.log('Unrecognised host - unable to set API base URL');
}

export default {
    getAvailability(business_id, business_location_id, booking_events, start_date, end_date) {
        return axios.get(
            baseUrl,
            {
                params: {
                    business_id,
                    business_location_id,
                    booking_events,
                    start_date,
                    end_date
                }
            }
        );
    }
};