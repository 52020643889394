import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Box, Button, CircularProgress, Slide } from '@mui/material';
import { FiArrowRight } from 'react-icons/fi';
import { formatMinuteIntToTime } from "./utils/formatting";
// Dayjs
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);
// Components
import Availability from './components/Availability';
import Calendar from './components/Calendar';
import NoAvailability from './components/NoAvailability';
// API
import availabilityApi from './api/availabilityApi';

class WizardFormLandingPage3 extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state= {
      availabilityData: {},
      calendarDate: dayjs(),
      selectedDate: null,
      selectedTime: null,
      minCalendarDate: null,
      maxCalendarDate: null,
      noAvailability: false,
      loading: true
    }
  }

  componentDidMount() {
    this.getAvailability();
  }

  async getAvailability() {
    const timezoneName = this.props.timezone_name;
    const bookingRange = this.props.booking_range;
    const businessId = this.props.business_id;
    const businessLocationId = this.props.business_location_id;
    try {
      const startDate = dayjs().tz(timezoneName, true);
      const minBookingDate = dayjs(startDate).add(this.props.advance_booking_mininum, 'minute');
      const endDate = dayjs(startDate).add(bookingRange, 'month');
      const { data: availabilityData } = await availabilityApi.getAvailability(
        businessId,
        businessLocationId,
        JSON.stringify(this.props.booking_data.map(x => { return { staff_id: x.staff_id, service_length: x.serviceDuration } })),
        minBookingDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );
      // Derive first available calendar date
      const startDateStr = minBookingDate.format('YYYY-MM-DD');
      let calendarDate = minBookingDate;
      let noAvailability = false;
      if(!startDateStr in availabilityData || availabilityData[startDateStr].length === 0) {
        calendarDate = this.findNextAvailableDate(availabilityData, minBookingDate);
        if(!calendarDate) {
          noAvailability = true;
        }
      }
      this.setState({
        availabilityData,
        loading: false,
        calendarDate,
        noAvailability,
        minCalendarDate: minBookingDate,
        maxCalendarDate: endDate
      });
    } catch(e) {
      console.error('Error loading times:', e);
      this.setState({ loading: false, noAvailability: true });
    }
  }

  findNextAvailableDate(availabilityData, date) {
    const availabilityKeys = Object.keys(availabilityData);
    const availabilityKeyIdx = availabilityKeys.indexOf(date.format('YYYY-MM-DD'));
    const remainingAvailabilityKeys = availabilityKeys.slice(availabilityKeyIdx);
    for(const key of remainingAvailabilityKeys) {
      if(availabilityData[key].length > 0) {
        return dayjs(key, 'YYYY-MM-DD');
      }
    }
  }

  renderAvailability() {
    const calendarDateKey = this.state.calendarDate.format('YYYY-MM-DD');
    let dateAvailabilityData = calendarDateKey in this.state.availabilityData ? this.state.availabilityData[calendarDateKey] : [];
    if(dateAvailabilityData.length > 0 && this.state.calendarDate.isSame(this.state.minCalendarDate, 'day')) {
      const minTimeInt = this.state.minCalendarDate.hour() * 60 + this.state.minCalendarDate.minute();
      dateAvailabilityData = dateAvailabilityData.filter(x => x >= minTimeInt); 
    }
    if(dateAvailabilityData.length === 0) {
      return (
        <NoAvailability variant={'date'}/>
      )
    }
    return (
      <Box sx={{ padding: '1rem 1.5rem', display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <Availability
          availabilityData={dateAvailabilityData}
          selectedTime={this.state.selectedTime}
          isSelectedDate={this.state.selectedDate === this.state.calendarDate.format('YYYY-MM-DD')}
          setSelectedTime={(timeInt) => this.setState({ selectedTime: timeInt, selectedDate: this.state.calendarDate.format('YYYY-MM-DD') })}
          styleSettings={this.props.business_settings}
        />
      </Box>
    );
  }

  submitSelectedTime() {
    if(!this.state.selectedTime) return;
    const selectedTime = formatMinuteIntToTime(this.state.selectedTime);
    this.props.dateChanged(this.state.calendarDate);
    this.props.addTime(selectedTime);
  }

  render() {
    return (
      <div className="left" style={{ backgroundColor: '#f0f0f0' }}>
        <div
          className="page-title"
          style={{color: this.props.business_settings?.times_header_title, backgroundColor: this.props.business_settings?.times_header_background}}
        >
          <div style={{ display: 'flex' }}>
            <div onClick={this.props.previousPage} style={{ cursor: 'pointer', marginRight: 10 }}>
              <FiChevronLeft size={20} color={this.props.business_settings?.times_header_title} style={{ marginBottom: -3 }}/>
            </div>
            <div>
              Availability 
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Button variant="contained" size="sm" sx={{ textTransform: 'unset' }} onClick={() => this.props.accountPage()}>
              Account
            </Button>
          </div>        
        </div>
        {!this.state.loading && !this.state.noAvailability && (
          <>
          <Calendar
            availabilityData={this.state.availabilityData}
            calendarDate={this.state.calendarDate}
            setCalendarDate={(date) => this.setState({ calendarDate: date }) }
            minDate={this.state.minCalendarDate}
            maxDate={this.state.maxCalendarDate}
          />
          {this.renderAvailability()}
          </>
        )}
        {this.state.noAvailability && <NoAvailability variant={'service'}/>}
        {this.state.loading && (
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={45} />
          </Box>
        )}
        <Slide direction="up" in={this.state.selectedTime} mountOnEnter unmountOnExit>
          <Button variant="contained" fullWidth style={{ minHeight: 45, borderRadius: 0 }} onClick={() => this.submitSelectedTime()}>
            Next <FiArrowRight style={{ marginLeft: 5 }}/>
          </Button>
        </Slide>
      </div>
    );
  };
}

export default WizardFormLandingPage3;