import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './Form.css';

import SignInForm from './components/SignInForm';
import SignUpForm from './components/SignUpForm';
import ForgotPassword from './components/ForgotPassword';

import remoteApi from './api/remoteApi';
  
class WizardFormLandingPage4 extends React.Component {

  constructor(props, context) {
    super(props, context);
    // ComponentToRender:
    // 0: Sign-in
    // 1: Sign-up
    // 2: Forgot password
    this.state = {
      componentToRender: 0,
      authLoading: true
    }
    this.changeScreen = this.changeScreen.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  componentDidMount() {
    this.checkLoggedIn();
  }

  async checkLoggedIn() {
    try {
      let response = await remoteApi.getUser();
      this.props.setUser(response.data.userData);
      this.props.nextPage();
    } catch(e) {
      this.setState({ authLoading: false });
    }
  }

  changeScreen() {
    if(this.state.componentToRender == 0){
      this.props.previousPage();
    } else if(this.state.componentToRender == 1){
      this.setState({componentToRender: 0});
    } else if(this.state.componentToRender == 2){
      this.setState({componentToRender: 0});
    }
  }

  createAccount() {
    this.setState({componentToRender: 1});
  }

  forgotPassword() {
    this.setState({componentToRender: 2});
  }

  render() {
    if(this.state.componentToRender === 0){
      return (
        this.state.authLoading ? (
          <div className="left">
            <div className="loading-container">
              <div className="loading-container-spinner">
                <CircularProgress size={40} color={'secondary'}/>
              </div>
            </div>
          </div>
        ) : (
          <SignInForm
            changeScreen={this.changeScreen}
            createAccount={this.createAccount}
            forgotPassword={this.forgotPassword}
            nextPage={this.props.nextPage}
            backNavigation={true}
            business_id={this.props.business_id}
            booking_data={this.props.booking_data}
            embedded={this.props.embedded}
            page={this.props.page}
            time={this.props.time}
            date={this.props.date}
            socialRedirectError={this.props.socialRedirectError}
          />
        )
      );
    } else if(this.state.componentToRender === 1){
      return (
        <SignUpForm
          changeScreen={this.changeScreen}
          nextPage={this.props.nextPage}
          business_id={this.props.business_id}
        />
      );
    } else if(this.state.componentToRender === 2){
      return (
        <ForgotPassword
          changeScreen={this.changeScreen}
          nextPage={this.props.nextPage}
          business_id={this.props.business_id}
        />
      );
    }
  }
};

export default (WizardFormLandingPage4);